// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import "~@fortawesome/fontawesome-free/css/all.css";
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$mfiq-web-primary: mat.define-palette(mat.$indigo-palette);
$mfiq-web-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$mfiq-web-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$mfiq-web-theme: mat.define-light-theme((
  color: (
    primary: $mfiq-web-primary,
    accent: $mfiq-web-accent,
    warn: $mfiq-web-warn,
  )
));
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($mfiq-web-theme);

/* You can add global styles to this file, and also import other style files */
$microma-font: 'Microma', sans-serif;
.height-full{
  height: 100%;
  overflow: auto;
}
html, body { height: 100%; overflow: auto; }
body { margin: 0; font-family: $microma-font;height: 100%; overflow: auto;}
@mixin form-field-height{
  height: 2rem;
}

.form-field{
  @include form-field-height();
  font-size: small;
}

.loader {
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #4974b9;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 0.8s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@font-face {
  font-family: 'Michroma';
  src: url('assets/Michroma-Regular.ttf');
  /* Add additional formats if necessary */
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/Roboto-Regular.ttf');
  /* Add additional formats if necessary */
}
